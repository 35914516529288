import { Footer } from "./footer"
import { Navigation } from "./navigation"
import React from "react"
import { Seo } from "./seo"
import styled from "styled-components"

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
`

export const Layout = ({ children }) => (
  <FlexContainer>
    <div>
      <Seo />
      <Navigation />
      {children}
    </div>
    <Footer />
  </FlexContainer>
)
