import { Button } from "./button"
import { Container } from "./container"
import { Link } from "gatsby"
import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import styled from "styled-components"

const StyledNav = styled.nav`
  padding: 1.5rem 0rem;
  justify-content: space-between;
  display: flex;
`

const StyledLink = styled(Link)`
  color: var(--black);
  text-decoration: none;
  font-family: "Lato";
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0.1rem;
  transition: all ease-in-out 0.3s;
  outline: none;

  @media (min-width: 668px) {
    font-size: 14px;
  }
  @media (min-width: 960px) {
    font-size: 16px;
  }

  &:hover {
    color: var(--red);
    transition: all ease-in-out 0.3s;
  }
`

export const Navigation = () => (
  <Container>
    <StyledNav>
      <div className="centered-v">
        <StyledLink to="/">JAMES WORDEN</StyledLink>
      </div>
      <Button onClick={() => scrollTo("#contact")}>Contact</Button>
    </StyledNav>
  </Container>
)
