import { ContactForm, Container, Headshot, Layout } from "../components"
import { StaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"

const StyledSection = styled.section`
  padding: 6rem 0rem;
`

const StyledH1 = styled.h1`
  margin: 0;
  padding: 3rem 0rem;
`

const StyledH2 = styled.h2`
  margin: 0;
`

const StyledH3 = styled.h3`
  margin-bottom: 0;
`

const Content = styled.div`
  display: flex;
`

const TextContainer2 = styled.p`
  @media (min-width: 700px) {
    max-width: 350px;
  }
`

const TextContainer = styled.p`
  @media (min-width: 600px) {
    max-width: 350px;
  }
`

const ContactTextContainer = styled.p`
  margin-top: 0rem;
  margin-bottom: 2.5rem;
  font-size: 18px;
`

const TextSection = styled.section`
  @media (min-width: 700px) {
    margin: 1.5rem 0rem;
  }
`

const ContactContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`

const ContactTextSection = styled.section`
  flex: 1;

  @media (min-width: 600px) {
    justify-content: space-around;
    display: flex;
    flex-direction: column;
  }
`

const Watermark = styled.span`
  transform: rotate(-90deg);
  font-size: 100px;
  text-transform: uppercase;
  font-family: "Lato";
  opacity: 0.1;
  color: var(--red);
  position: absolute;
  overflow: hidden;
  right: -11.25rem;

  // This is to align 'ABOUT' vertically.
  // Change this if you wish to change your watermark content
  &:after {
    content: "\\00A0\\00A0\\00A0\\00A0\\00A0\\00A0\\00A0";
  }

  @media (max-width: 600px) {
    display: none;
  }
  @media (min-width: 668px) {
    right: -10.25rem;
  }
`

const ContactSection = styled.section`
  background-color: var(--lightgray);
  padding: 6rem 0rem;
`

const ImageContainer = styled.div`
  outline: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2),
    0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 280px;
  max-height: 279px;
  transition: ease-in-out 0.3s;
  cursor: pointer;
  margin: auto;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;

  @media (min-width: 700px) {
    margin: 1.5rem 0rem;
  }

  &:hover {
    transform: scale(1.02);
  }
`

const StyledUL = styled.ul`
  list-style: none;
  margin-top: 0.25rem;
  margin-bottom: 2rem;

  * {
    display: inline-block;
    margin-right: 3rem;

    &:before {
      content: "\\25e6";
      color: var(--red);
      margin: 0 0.5rem;
      margin-left: -2.2rem;
    }
  }
`

const StyledA = styled.a`
  text-decoration: none;
  color: var(--black);
  font-weight: bold;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: var(--black);
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 700px) {
    flex-direction: row-reverse;
  }
`

const Image = () => (
  <ImageContainer
    onClick={() => window.open("https://www.bobworden.com")}
    onKeyDown={() => window.open("https://www.bobworden.com")}
    role="button"
    tabIndex={0}
  >
    <StaticQuery
      query={graphql`
        query {
          bobworden: file(relativePath: { eq: "bobworden.png" }) {
            childImageSharp {
              fixed(width: 280) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => <Img fixed={data.bobworden.childImageSharp.fixed} />}
    />
  </ImageContainer>
)

export default () => (
  <Layout>
    <Container>
      <header>
        <StyledH1>
          I'm James, <br /> a web application developer.
        </StyledH1>
        <div className="centered">
          <Headshot />
        </div>
      </header>
    </Container>
    <Container>
      <StyledSection>
        <Content>
          <section>
            <StyledH2>
              I design and develop <br /> modern software systems...
            </StyledH2>
            <TextContainer>
              ...while attending St. John's University. I'm pursuing a
              bachelor's degree in Computer Science and a minor in Mathematics.
            </TextContainer>
          </section>
          <Watermark>About</Watermark>
        </Content>
      </StyledSection>
    </Container>
    <StyledSection>
      <Container>
        <StyledContent>
          <TextSection>
            <StyledH2>Check out my work!</StyledH2>
            <TextContainer2>
              <StyledA href="https://www.bobworden.com" target="_blank">
                Bobworden.com
              </StyledA>{" "}
              serves articles and blog posts using Gatsby and WordPress as a
              headless Content Management System to render static files for fast
              performance.
            </TextContainer2>
            <StyledH3>Technology:</StyledH3>
            <StyledUL>
              <li>GatsbyJS</li>
              <li>NodeJS</li>
              <li>WordPress</li>
            </StyledUL>
          </TextSection>
          <Image />
        </StyledContent>
      </Container>
    </StyledSection>
    <ContactSection id="contact">
      <Container>
        <ContactContent>
          <ContactTextSection>
            <div>
              <StyledH1>Reach out!</StyledH1>
              <ContactTextContainer>
                I'm always searching for new <br />
                opportunities to create and collaborate.
              </ContactTextContainer>
              <StyledH3>james@jamesworden.com</StyledH3>
            </div>
          </ContactTextSection>
          <ContactForm />
        </ContactContent>
      </Container>
    </ContactSection>
  </Layout>
)
