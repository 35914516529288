import { Container, Layout } from "../components"

import React from "react"

export default () => (
  <Layout>
    <Container>
      <h1>Thanks for reaching out!</h1>
      <p>I'll get back to you shortly.</p>
    </Container>
  </Layout>
)
