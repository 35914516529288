import React from "react"
import styled from "styled-components"

const StyledDiv = styled.div`
  padding: 0rem 1rem;
  overflow: hidden;
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: 800px;
  position: relative;

  @media (min-width: 668px) {
    padding: 0rem 2rem;
  }
`

export const Container = ({ children }) => (
  <div className="centered">
    <StyledDiv>{children}</StyledDiv>
  </div>
)
