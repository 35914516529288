import { StaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"

const StyledImg = styled(Img)`
  justify-content: center;
  margin: auto;
  width: 100%;
  margin: 1.5rem 0rem;

  @media (max-width: 350px) {
    clip-path: polygon(12.5% 0%, 87.5% 0%, 87.5% 100%, 12.5% 100%);
    transform: scale(1.334);
    margin: 3rem 0rem;
    max-width: 320px;
  }
`

export const Headshot = () => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "headshot.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 540) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    // @ts-ignore - mysterious error with fluid property
    render={data => <StyledImg fluid={data.image.childImageSharp.fluid} />}
  />
)
