import React from "react"
import styled from "styled-components"

const StyledFooter = styled.footer`
  position: relative;
  left: 0;
  bottom: 0;
  color: var(--white);
  background-color: var(--darkgray);
  width: 100%;
  padding: 2rem 0rem;
  display: flex;
  justify-content: space-around;
`

const Content = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  max-width: 30rem;
  padding: 0rem 1rem;
`

const StyledA = styled.a`
  font-family: "Cardo";
  font-style: bold;
  font-size: 16px;
  color: var(--white);
  transition: ease-in-out 0.3s all;
  text-decoration: none;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: var(--white);
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`

export const Footer = () => (
  <StyledFooter>
    <div className="centered">
      <Content>
        <StyledA href="mailto:james@jamesworden.com">Email</StyledA>
        <StyledA
          href="https://www.linkedin.com/in/jameswordenny/"
          target="_blank"
        >
          LinkedIn
        </StyledA>
        <StyledA href="https://github.com/jamesworden/" target="_blank">
          GitHub
        </StyledA>
      </Content>
    </div>
  </StyledFooter>
)
