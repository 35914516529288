import styled from "styled-components"

export const Button = styled.button`
  color: var(--red);
  background-color: transparent;
  padding: 0.5rem 1rem;
  font-family: "Lato";
  font-size: 15px;
  transition: all 0.3s ease-in-out;
  border: 1px solid var(--red);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  outline: none;
  cursor: pointer;

  &:hover {
    border: 1px solid transparent;
    background-color: var(--red);
    color: var(--white);
  }
`
