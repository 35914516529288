import { Layout } from "../components"
import React from "react"

export default () => (
  <Layout>
    <h1>Page not Found !</h1>
    <p>
      Click my name on the upper left hand corner of the screen to return home!
    </p>
  </Layout>
)
